

import {defineComponent, ref} from 'vue';
import {useMainStore} from 'stores/main';
import {useRoute, useRouter} from 'vue-router';
import {Loading, Notify, useQuasar} from 'quasar';
import MessageModal from 'src/components/modals/MessageModal.vue';
import Messages from 'src/api/messages';


interface MessageOptions {
  chatUuid?: string;
  participants?: string[];
  advertUuid?: string;
  itemUuid?: string;
}

export const getChat = (
  advertUuid: string | undefined = undefined,
  itemUuid: string | undefined = undefined,
  participants: Array<string> | undefined = undefined
) => {
  if (!advertUuid && !participants?.length && !itemUuid) throw 'Needs either chatUuid or participants or advert or item'
  return Messages.getOrCreateChat(advertUuid, itemUuid, participants)
}

export const createMessage = async (
  message,
  {
    chatUuid = undefined,
    participants = undefined,
    advertUuid = undefined,
    itemUuid = undefined,
  }: MessageOptions = {}
) => {

  if (!chatUuid) {
    const resp = await getChat(advertUuid, itemUuid, participants)
    chatUuid = resp.data.uuid
  }
  Loading.show()
  Messages.createMessage(chatUuid, {content: message}).then((resp) => {
    return resp
  }).finally(() => {
    Loading.hide()
  })
}

export default defineComponent({
  props: {
    showModal: {
      default: true,
      type: Boolean
    },
    advertUuid: {
      required: false,
      type: String
    },
    itemUuid: {
      required: false,
      type: String
    },
    userUuid: {
      required: false,
      type: String
    },
    label: {
      default: 'Nachricht schreiben'
    },
    hasIcon: {
      type: Boolean,
      default: false
    },
    big: {
      type: Boolean,
      default: false,
    }
  },

  setup(props) {
    const mainStore = useMainStore()
    const router = useRouter()
    const route = useRoute()
    const $q = useQuasar()
    const loading = ref(false)

    const messageButtonClick = (
      userUuid: string | undefined,
      advertUuid: string | undefined = undefined,
      itemUuid: string | undefined = undefined,
      showModal = false
    ) => {
      loading.value = true
      if (!mainStore.isLoggedIn) {
        router.push({name: 'login', query: {n: route.fullPath, required: 'yes'}})
      } else if (showModal) {

        getChat(advertUuid, itemUuid, userUuid ? [userUuid] : undefined).then(() => {
          $q.dialog({
            component: MessageModal,
            componentProps: {
              userUuid: userUuid,
              advertUuid: advertUuid,
              itemUuid: itemUuid,
            }
          })
        }).catch(err => {
          Notify.create({
            color: 'negative',
            message: err.response.data
          })
        }).finally(() => {
          loading.value = false
        })

      } else {
        getChat(advertUuid, itemUuid, userUuid ? [userUuid] : undefined).then(resp => {
          router.push({name: 'messenger-chat', params: {chatUuid: resp.data.uuid}}).finally(() => {
            loading.value = false
          })
        }).catch(err => {
          Notify.create({
            color: 'negative',
            message: err.response.data
          })
        }).finally(() => {
          loading.value = false
        })
      }
    }

    const click = function () {
      messageButtonClick(props.userUuid, props.advertUuid, props.itemUuid, props.showModal)
    }
    return {
      click,
      loading,
    }
  }
})

